import PropTypes from 'prop-types';

import { EElectricalPowerUnit } from './extendedFields';
import {
  TIdLocationDict,
  TLocaleSet,
  PTLocaleSet,
  PTIdLocationDict,
  TMinMax
} from './general';
import { TSelectOption } from 'types';

export type TCountryISO = {
  iso: string;
  isoAplha3: string;
  value: TLocaleSet;
};

export const PTCountryISO = {
  iso: PropTypes.string.isRequired,
  isoAplha3: PropTypes.string.isRequired,
  value: PropTypes.exact(PTLocaleSet).isRequired
};

export type TEngine = {
  details: string;
  manufacturerId: number;
  manufacturerName: string;
  power: number;
  powerUnits?: EElectricalPowerUnit;
};

export const PTEngine = {
  details: PropTypes.string,
  manufacturerId: PropTypes.number,
  manufacturerName: PropTypes.string,
  power: PropTypes.number
};

export type TEngineShort = {
  id: number;
  name: string;
  serviceProviderId: number;
};

// todo dismiss
export type TLanguage = {
  label: string;
  value: string;
};

export type TLanguageISO = {
  value: TLocaleSet;
  iso: string;
};

export enum ESupportedApps {
  AQUAFACTS = 'AQUAFACTS',
  FISHFACTS = 'FISHFACTS'
}

export enum ESupportedFields {
  AQUADETAILS = 'AQUADETAILS',
  DECK = 'DECK',
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  INSTALLATIONS = 'INSTALLATIONS',
  PROPULSION = 'PROPULSION'
}

export type TVesselType = TIdLocationDict & {
  parentId: number;
  subTypes: TVesselType[];
  supportedApps: ESupportedApps[];
  value: TLocaleSet;
  supportedFields: ESupportedFields[];
};

export const PTVesselType = {
  subTypes: undefined,
  ...PTIdLocationDict,
  parentId: PropTypes.number,
  supportedApps: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ESupportedApps))
  ),
  supportedFields: PropTypes.oneOf(Object.values(ESupportedFields)),
  value: PropTypes.exact(PTLocaleSet)
};

PTVesselType.subTypes = PropTypes.arrayOf(PropTypes.exact(PTVesselType));

export type TManufacturer = {
  id: number;
  name: string;
  serviceProviderId: number;
};

export type TFilterRangeDict = {
  lengthRange: TMinMax;
  powerRange: TMinMax;
  tonnageRange: TMinMax;
  yearRange: TMinMax;
};

// export type TVesselGear = TIdLocationDict;
// export type TWeightRanges = TIdName;

export type TDictionaries = {
  companyStatuses: TIdLocationDict[];
  countries: TSelectOption<string>[];
  countriesISO: TCountryISO[];
  crmVesselTypes: TVesselType[];
  engines: TEngineShort[];
  filterRanges: TFilterRangeDict;
  fishFarmStatuses: TIdLocationDict[];
  fishFarmTypes: TSelectOption<string>[];
  languages: TSelectOption<string>[];
  manufacturers: TManufacturer[];
  promotionCountries: TSelectOption<string>[];
  proposalRejectReasons: TIdLocationDict[];
  species: TSelectOption<number>[];
  vesselGearTypes: TSelectOption<number>[];
  vesselTypes: TVesselType[];
  vesselStatuses: TIdLocationDict[];
  // weightRanges: TWeightRanges[];
};
