import { ENDPOINTS } from 'other/config';
import { fetchCustomReportAction } from '../misc/miscActions';
import { fetchFleetsAction } from 'store/fleets/fleetsActions';
import { fetchPromotedAction } from 'store/promo/promoActions';
import { fetchSet } from './dictionariesConstants';
import {
  handleLanguages,
  handleRanges,
  handleSpecies,
  mapISOCountries,
  sortCountries
} from './helpers';
import { http } from 'services/http';

import { TCountryISO } from 'types';
import { TDictionariesState } from './dictionariesModel';
import { THttpResponse } from 'services/HttpClass';
import { TState } from '../appStateModel';

/**
 * Delegates fetching of some commonly used data structures.
 */
export function prefetchDataAction(isForce?: boolean) {
  return (dispatch, getState) => {
    const { session } = getState() as TState;
    const isUserSessionActive = session.error || !session.user;

    if (isUserSessionActive && !isForce) {
      return dispatch(fetchPromotedAction());
    }

    const arr = [
      fetchFleetsAction,
      fetchDictionariesAction,
      fetchPromotedAction,
      fetchCustomReportAction
    ];
    arr.forEach((action) => dispatch(action()));
  };
}

/**
 * Fetches dictionaries used across the application.
 */
export function fetchDictionariesAction() {
  return async (dispatch) => {
    dispatch(fetchSet.request());

    try {
      const { data } = (await http.send(
        ENDPOINTS.DICTIONARIES
      )) as THttpResponse<any>;

      const {
        countries,
        fishFarmTypes,
        languages,
        promotionCountries,
        species,
        vesselFilter,
        vesselGearTypes,
        ...rest
      } = data;
      const sortedCountries: TCountryISO[] = sortCountries(countries);

      const payload: Partial<TDictionariesState> = {
        countries: mapISOCountries(sortedCountries),
        countriesISO: sortedCountries,
        filterRanges: handleRanges(vesselFilter),
        fishFarmTypes: handleSpecies(fishFarmTypes),
        languages: handleLanguages(languages),
        promotionCountries: mapISOCountries(sortCountries(promotionCountries)),
        species: handleSpecies(species),
        vesselGearTypes: handleSpecies(vesselGearTypes),
        ...rest
      };

      dispatch(fetchSet.success(payload));
    } catch (e) {
      dispatch(fetchSet.error(e));
    }
  };
}
