import merge from 'lodash.merge';
import { ThemeConfig } from 'antd/lib/config-provider/context';

import { BODY_DARK_CLASS, BODY_LIGHT_CLASS } from 'other/constants';
import common from 'styles/cssVariables/json/common.json';
import commonDark from 'styles/cssVariables/json/commonDark.json';
import commonLight from 'styles/cssVariables/json/commonLight.json';
import { commonVariables } from 'styles/cssVariables/js/common';
import { darkCommonVariables } from 'styles/cssVariables/js/commonDark';
import { ENDPOINTS, ENV } from 'other/config';
import { http } from 'services/http';
import { isMapLikePage } from 'other/helpers';
import { lightCommonVariables } from 'styles/cssVariables/js/commonLight';
import { miscInitialState } from './miscInitialState';
import store, { history } from '../store';
import { SettingsService } from 'services/settings';

import { EMiscActions, fetchSet } from './miscConstants';
import { TAnalyticsReport } from 'types/report';
import { THttpResponse } from 'services/HttpClass';

let prevLocation = miscInitialState.prevLocation as any;

/**
 *
 */
export function getThemeIsDark(): boolean {
  const isDarkTheme =
    SettingsService.readSettings()[SettingsService.IS_DARK_THEME];

  return isDarkTheme === void 0
    ? miscInitialState.theme.isGenerallyDark
    : isDarkTheme;
}

/**
 *
 */
function toggleTheme(isDark: boolean): void {
  applyCssVars(isDark ? commonDark : commonLight);
  applyCssVars(isDark ? ENV.CSS_VARS.DARK : ENV.CSS_VARS.LIGHT);

  if (isDark) {
    document.body.classList.add(BODY_DARK_CLASS);
    document.body.classList.remove(BODY_LIGHT_CLASS);
  } else {
    document.body.classList.remove(BODY_DARK_CLASS);
    document.body.classList.add(BODY_LIGHT_CLASS);
  }
}

/**
 *
 */
export function initializeThemeAction() {
  return (dispatch, getState) => {
    const { misc, router } = getState();
    const _isDark = getThemeIsDark();

    const isDark = isMapLikePage(router.location.pathname) ? true : _isDark;

    applyCssVars(common);
    applyCssVars(ENV.CSS_VARS.COMMON);
    toggleTheme(isDark);
    watchLocation();

    const theme = merge({}, misc.theme, {
      isDark: isDark,
      settings: composeTheme(isDark)
    });

    dispatch({
      type: EMiscActions.INIT_THEME,
      payload: { theme }
    });
  };
}

/**
 *
 */
export function setGeneralThemeAction(isDark: boolean) {
  return (dispatch) => {
    SettingsService.writeSettings({ [SettingsService.IS_DARK_THEME]: isDark });
    dispatch(setThemeAction(isDark, { isGenerallyDark: isDark }));
  };
}

/**
 *
 */
export function setMapThemeAction(isDark: boolean) {
  return (dispatch) => {
    dispatch(setThemeAction(isDark, { isMapDark: isDark }));
  };
}

/**
 *
 */
export function setThemeAction(
  isDark: boolean,
  partial?: {
    isGenerallyDark?: boolean;
    isMapDark?: boolean;
  }
) {
  return (dispatch, getState) => {
    const { misc } = getState();
    toggleTheme(isDark);

    const theme = merge({}, misc.theme, {
      isDark: isDark,
      ...(partial || {}),
      settings: composeTheme(isDark)
    });

    dispatch({
      type: EMiscActions.SET_THEME,
      payload: { theme }
    });
  };
}

/**
 *
 */
function checkMapThemeAction(prevLocation, location) {
  return (dispatch, getState) => {
    const { misc } = getState();

    if (
      !isMapLikePage(prevLocation.pathname) &&
      isMapLikePage(location.pathname) &&
      misc.theme.isMapDark !== misc.theme.isDark
    ) {
      dispatch(setThemeAction(misc.theme.isMapDark));
    } else if (
      isMapLikePage(prevLocation.pathname) &&
      !isMapLikePage(location.pathname) &&
      misc.theme.isGenerallyDark !== misc.theme.isDark
    ) {
      dispatch(setThemeAction(misc.theme.isGenerallyDark));
    }
  };
}

/**/
function applyCssVars(vars: Record<string, string>): void {
  const root = document.documentElement.style;
  Object.entries(vars).forEach(([key, value]) => root.setProperty(key, value));
}

/**/
function composeTheme(isDark: boolean): ThemeConfig {
  return merge(
    {},
    commonVariables,
    ENV.THEME.COMMON,
    isDark ? ENV.THEME.DARK : ENV.THEME.LIGHT,
    isDark ? darkCommonVariables : lightCommonVariables
  );
}

/**/
function watchLocation(): void {
  history.listen((location) => {
    store.dispatch({
      type: EMiscActions.SAVE_PREV_LOCATION,
      payload: { prevLocation: prevLocation }
    });
    store.dispatch(checkMapThemeAction(prevLocation, location));
    prevLocation = location;
  });
}

/**
 *
 */
export function fetchCustomReportAction() {
  return (dispatch) => {
    dispatch(fetchSet.request());

    http
      .send(ENDPOINTS.POWER_BI_REPORTS)
      .then(({ data }: THttpResponse<Record<string, TAnalyticsReport>>) =>
        dispatch(fetchSet.success({ analyticReports: data }))
      )
      .catch((e) => dispatch(fetchSet.error(e)));
  };
}
