import { basicInitialState } from '../_utils/basicInitialState';
import { TMiscState } from './miscModel';

export const miscInitialState: TMiscState = {
  ...basicInitialState,
  theme: {
    isDark: false,
    isGenerallyDark: false,
    isMapDark: true,
    settings: {} as any
  },
  prevLocation: {
    hash: '',
    key: '',
    pathname: '',
    search: ''
  },
  // scrollTop or rowIndex, etc.
  scrollValue: null,
  analyticReports: {}
};
