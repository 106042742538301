import createAsyncActions from '../_utils/createAsyncActions';
import { TMiscState } from './miscModel';

export enum EMiscActions {
  FETCH_CUSTOM_REPORT = 'misc/FETCH_CUSTOM_REPORT',
  INIT_THEME = 'misc/INIT_THEME',
  SET_MAP_THEME = 'misc/SET_MAP_THEME',
  SET_GENERAL_THEME = 'misc/SET_GENERAL_THEME',
  SET_THEME = 'misc/SET_THEME',
  SAVE_PREV_LOCATION = 'misc/SAVE_PREV_LOCATION'
}

export const fetchSet = createAsyncActions<TMiscState>(
  EMiscActions.FETCH_CUSTOM_REPORT
);
