import React from 'react';
import classnames from 'classnames';
import Switch from 'antd/lib/switch';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './ThemeToggle.scss';
import { GA } from 'services/analytics';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import { isMapLikePage } from 'other/helpers';
import {
  setMapThemeAction,
  setGeneralThemeAction
} from 'store/misc/miscActions';
import { TState } from 'store/appStateModel';

/**/
type Props = {
  isDark: boolean;
  setTheme: (isDark: boolean) => void;
};

const checkedIcon = (
  <IconCustom
    className="ThemeToggle__icon ThemeToggle__icon--checked"
    type="moon"
  />
);
const uncheckedIcon = (
  <IconCustom
    className="ThemeToggle__icon ThemeToggle__icon--unchecked"
    type="sun"
  />
);

/**
 *
 */
class ThemeToggleInner extends React.Component<Props> {
  /**/
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return nextProps.isDark !== this.props.isDark;
  }

  /**/
  handleToggleTheme = () => {
    const { isDark, setTheme } = this.props;
    setTheme(!isDark);
  };

  /**/
  render() {
    const { isDark } = this.props;
    const cls = classnames('ThemeToggle__switch', {
      'ThemeToggle__switch--checked': isDark
    });

    return (
      <div className="ThemeToggle">
        <Switch
          checked={isDark}
          checkedChildren={checkedIcon}
          className={cls}
          onChange={this.handleToggleTheme}
          unCheckedChildren={uncheckedIcon}
        />
      </div>
    );
  }
}

/**
 *
 */
function ThemeToggle() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMap = isMapLikePage(location.pathname);

  const { isDark } = useSelector(({ misc }: TState) => ({
    isDark: misc.theme.isDark
  }));

  function setTheme(isDark: boolean) {
    if (isMap) {
      dispatch(setMapThemeAction(isDark));
    } else {
      dispatch(setGeneralThemeAction(isDark));
      isDark && GA.reportDarkMode();
    }
  }

  return <ThemeToggleInner isDark={isDark} setTheme={setTheme} />;
}

/**/
export default React.memo(ThemeToggle);
