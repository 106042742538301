import React from 'react';
import Collapse from 'antd/lib/collapse';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import './Navigation.scss';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import NavGroupHeader from './NavItem/NavGroupHeader';
import NavItem from './NavItem/NavItem';
import { ROUTE_ITEMS } from 'other/routeItems';
import { SettingsService } from 'services/settings';

import { TAnalyticsReport } from 'types/report';
import { TNavigationGroup, TNavigationItem } from 'types';
import { TState } from 'store/appStateModel';

/**/
type OwnProps = {
  closeAside: () => void;
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  items: ReadonlyArray<TNavigationGroup | TNavigationItem>;
};

type Props = RouteComponentProps &
  OwnProps & {
    customReport: TAnalyticsReport;
  };

type State = {
  activeKeys: string[];
};

/**/
const expandIcon = () => (
  <IconCustom className="Navigation__chevron" type="chevron-up" />
);

/**
 *
 */
class Component extends React.Component<Props, State> {
  state: State = {
    activeKeys: SettingsService.readSettings()[SettingsService.NAVIGATION] || []
  };

  /**/
  isActive = ({ id, path }: TNavigationItem): boolean => {
    const { isLoggedIn, location, match } = this.props;
    if (isLoggedIn) return location.pathname === path;
    if (!match) return false;
    return location.state && location.state['id'] === id;
  };

  /**/
  getActiveKey(key: string): string {
    if (!this.props.isAsideOpen) return null;
    return this.state.activeKeys.includes(key) && key;
  }

  /**/
  renderGroup = (item: TNavigationGroup) => {
    const { isAsideOpen, isLoggedIn } = this.props;
    const handle = (keys: string[]) => this.handleCollapse(item.key, !!keys[0]);
    const isActive = item.items.some(this.isActive);

    const items = [
      {
        key: item.key,
        label: (
          <NavGroupHeader
            isActive={isActive}
            isCollapsed={!isAsideOpen}
            isLoggedIn={isLoggedIn}
            item={item}
          />
        ),
        children: (
          <ul className="Navigation">{item.items.map(this.renderItem)}</ul>
        )
      }
    ];

    return (
      <li className="" key={item.title}>
        <Collapse
          activeKey={this.getActiveKey(item.key)}
          bordered={false}
          className="Navigation__group"
          expandIcon={expandIcon}
          ghost={true}
          items={items}
          onChange={handle}
          size="small"
        />
      </li>
    );
  };

  /**/
  renderItem = (item: TNavigationItem) => {
    const { closeAside, isAsideOpen, isLoggedIn } = this.props;
    return (
      <li className="Navigation__item" key={item.title}>
        <NavItem
          closeAside={closeAside}
          isCollapsed={!isAsideOpen}
          isLoggedIn={isLoggedIn}
          item={item}
        />
      </li>
    );
  };

  /**/
  renderCustomReport() {
    const { customReport } = this.props;
    if (!customReport) return null;

    return this.renderItem({
      ...ROUTE_ITEMS.CUSTOM_REPORT,
      title: customReport.reportName
    });
  }

  /**/
  handleCollapse(key: string, isExpanded: boolean): void {
    const set = new Set(this.state.activeKeys);
    isExpanded ? set.add(key) : set.delete(key);
    SettingsService.writeSettings({ [SettingsService.NAVIGATION]: [...set] });
    this.setState({ activeKeys: [...set] });
  }

  /**/
  render() {
    return (
      <ul className="Navigation app-overline-1">
        {this.props.items.map((item: TNavigationGroup | TNavigationItem) =>
          'key' in item ? this.renderGroup(item) : this.renderItem(item)
        )}
        {this.renderCustomReport()}
      </ul>
    );
  }
}

/**
 *
 */
function NavigationInner(ownProps: RouteComponentProps & OwnProps) {
  const { customReport } = useSelector((state: TState) => ({
    customReport: state.misc.analyticReports.customReport
  }));

  return <Component {...ownProps} customReport={customReport} />;
}

/**
 *
 */
const Navigation = withRouter(NavigationInner) as any;
export { Navigation };
