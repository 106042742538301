import PropTypes from 'prop-types';
import { EUserAuthority } from './user';

export type TCategoryListItem = {
  id?: string;
  imageSrc?: string;
  path: string;
  requiredPermissions?: EUserAuthority[];
  title: string;
};

export const PTCategoryListItem = {
  id: PropTypes.string,
  imageSrc: PropTypes.string,
  path: PropTypes.string.isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string) as any,
  title: PropTypes.string.isRequired
};

export type TNavigationItem = {
  extra?: string;
  icon: string;
  id?: string;
  path: string;
  title: string;
  authorities?: EUserAuthority[];
};

export type TNavigationGroup = {
  // extra?: string;
  icon: string;
  items: TNavigationItem[];
  key: string;
  title: string;
  // authorities?: EUserAuthority[];
};
