import message from 'antd/lib/message';

import {
  ENDPOINTS,
  ENV,
  VESSELS_LOCATIONS_UPDATE_INTERVAL
} from 'other/config';
import { fetchLocationsAction } from '../farmsLocations/farmsLocationsActions';
import { getLocationFailMessage } from 'components/common/messages/LocationFailMessage';
import { http } from 'services/http';
import { refreshVesselEntriesAction } from '../mapEntities/mapEntitiesActions';
import store from '../../store';
import { updateTracksAction } from '../tracks/tracksActions';

import {
  EVesselsLocationsActions,
  locationsSet
} from './vesselsLocationsConstants';
import { EMapView, EUserAuthority, TVesselLocation } from 'types';
import { TAction } from '../../_utils/reducerCreator';
import { TFiltersModel } from '../../filters/filtersModel';
import { THttpResponse } from 'services/HttpClass';
import { TState } from '../../appStateModel';
import { TVesselsLocationsState } from './vesselsLocationsModel';

// Preserve for debug purpose.
// const locs = [
//   {
//     id: 1573,
//     name: 'TEST1926',
//     flag: 'CA',
//     type: 16,
//     latitude: 51.88455,
//     longitude: 4.415532,
//     speed: 0,
//     heading: 110,
//     status: '5',
//     lastUpdate: '2020-01-20T12:09:15Z'
//   },
//   {
//     id: 1209,
//     name: 'AART MAASKANT',
//     flag: 'NL',
//     type: 15,
//     latitude: 52.36725,
//     longitude: 4.14088,
//     speed: 1.5,
//     heading: 314,
//     status: '',
//     lastUpdate: '2021-05-03T09:13:04Z',
//     photo: {
//       id: 10307,
//       type: 'VESSEL',
//       format: 'PHOTO',
//       path: 'NL/AART MAASKANT-30a580895cff6200905b2a6227e334bb.png',
//       title: 'AART MAASKANT',
//       copyright: null,
//       size: null
//     }
//   }
// ];
// let count = 0;
// function getLocs() {
//   count -= 0.03;
//   return [
//     {
//       ...locs[0],
//       latitude: locs[0].latitude + count,
//       longitude: locs[0].longitude + count
//     },
//     {
//       ...locs[1],
//       latitude: locs[1].latitude + count,
//       longitude: locs[1].longitude + count
//     }
//   ] as any;
// }

/**/
let locationFailMessage;

/**
 * Starts interval location retrieves. First stops the previous job if any. If the provider filter
 * is different from its defaults, it means that no vessel locations should be shown. So, no task
 * is triggered.
 */
export function watchLocationsAction(preserveData?: boolean) {
  return (dispatch, getState) => {
    const {
      providers: { filterSettings }
    } = getState() as TState;

    dispatch(unwatchLocationsAction(preserveData));

    if (
      filterSettings.mapFilterCategories !== null &&
      filterSettings.isMapFilterSet
    ) {
      return;
    }

    const action: TAction<TVesselsLocationsState, EVesselsLocationsActions> = {
      type: EVesselsLocationsActions.START_WATCHING_LOCATIONS,
      payload: {
        locationTimer: startLocationWatch() as any
      }
    };
    dispatch(action);
  };
}

/**
 * Cancels interval location updates.
 */
export function unwatchLocationsAction(preserveData?: boolean) {
  return (dispatch, getState) => {
    const {
      vesselsLocations: { locationTimer }
    } = getState() as TState;

    clearInterval(locationTimer);
    document.removeEventListener('visibilitychange', triggerLocationUpdate);

    locationTimer &&
      dispatch({
        type: EVesselsLocationsActions.STOP_WATCHING_LOCATIONS,
        payload: {
          locationTimer: null,
          ...(preserveData ? {} : { locations: null })
        }
      });
  };
}

/**
 * Fires interval location fetch task.
 */
export function startLocationWatch() {
  store.dispatch(fetchLocations());
  document.addEventListener('visibilitychange', triggerLocationUpdate);

  return setInterval(
    () => store.dispatch(fetchLocations()),
    VESSELS_LOCATIONS_UPDATE_INTERVAL
  );
}

/**
 *
 */
function triggerLocationUpdate() {
  document.hidden || store.dispatch(fetchLocations());
}

/**
 *
 */
function retry() {
  message.destroy();
  locationFailMessage = null;
  store.dispatch(fetchLocations());
  ENV.IS_AF && store.dispatch(fetchLocationsAction());
}

/**
 * Fetches locations (map vessel entries) with respect to the filter settings.
 */
export function fetchLocations() {
  return (dispatch, getState) => {
    const { filters, mapOptions, session } = getState() as TState;
    const url = getLocationQuery(filters);
    if (!url) return;

    dispatch(locationsSet.request());
    const isDev = session.user?.userInfo.authorities.includes(
      EUserAuthority.DEVELOPER
    );

    http
      .send(url)
      .then(({ data }: THttpResponse<TVesselLocation[]>) => {
        const _data =
          mapOptions.primaryView === EMapView.ATLANTIC
            ? data
            : data.map(
                (item: TVesselLocation): TVesselLocation => ({
                  ...item,
                  longitude:
                    item.longitude > 0 ? item.longitude : item.longitude + 360
                })
              );

        dispatch(locationsSet.success({ locations: _data }));
        dispatch(performUpdates(_data));
        message.destroy();

        isDev &&
          message.info(
            {
              className: 'LocationsUpdate__message',
              content: 'Vessel locations updated.'
            },
            0.35
          );
      })
      .catch((e) => {
        dispatch(locationsSet.error(e));
        if (!locationFailMessage) {
          locationFailMessage = message.warning(getLocationFailMessage(retry));
        }
      });
  };
}

/**
 *
 */
function performUpdates(locations: TVesselLocation[]) {
  return (dispatch, getState) => {
    const { mapEntities } = getState() as TState;
    const entries = mapEntities.vessels.map((e: TVesselLocation) => e.id);
    const targets = locations.filter((l: TVesselLocation) =>
      entries.includes(l.id)
    );

    dispatch(refreshVesselEntriesAction(targets));
    dispatch(updateTracksAction(targets));
  };
}

/**
 * Returns a query string with respect to the filter settings.
 * @param settings
 */
export function getLocationQuery(settings: TFiltersModel): string {
  const {
    buildYearValue,
    companyValue,
    countryValue,
    engineModelValue,
    enginePowerValue,
    fleetValue,
    lengthValue,
    tonnageValue,
    vesselTypeValue
  } = settings;
  let req = '';
  if (!vesselTypeValue) return null;

  if (fleetValue.length > 0) req += `&fleetId=${fleetValue.join(',')}`;
  if (companyValue.length > 0) req += `&companyIds=${companyValue.join(',')}`;
  if (countryValue.length > 0) req += `&flag=${countryValue.join(',')}`;
  if (vesselTypeValue?.length > 0)
    req += `&vesselTypeId=${vesselTypeValue.join(',')}`;
  if (engineModelValue.length > 0)
    req += `&engineModelId=${engineModelValue.join(',')}`;
  if (tonnageValue)
    req += `&bruttoTonsMax=${tonnageValue.max}&bruttoTonsMin=${tonnageValue.min}`;
  if (lengthValue)
    req += `&lengthMax=${lengthValue.max}&lengthMin=${lengthValue.min}`;
  if (enginePowerValue)
    req += `&enginePowerMax=${enginePowerValue.max}&enginePowerMin=${enginePowerValue.min}`;
  if (buildYearValue)
    req += `&buildYearMax=${buildYearValue.max}&buildYearMin=${buildYearValue.min}`;

  return `${ENDPOINTS.VESSELS_LOCATION}?${req.slice(1)}`;
}
